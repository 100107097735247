import "/build/app/node_modules/core-js/modules/es.array.iterator.js";
import "/build/app/node_modules/core-js/modules/es.promise.js";
import "/build/app/node_modules/core-js/modules/es.object.assign.js";
import "/build/app/node_modules/core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './permission'; // permission control

Vue.config.productionTip = false;
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');