import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
var routes = [{
  path: '/index',
  name: 'home',
  component: function component() {
    return import( /* webpackChunkName: "about" */'../views/Home.vue');
  }
}, {
  path: '/404',
  name: '404',
  component: function component() {
    return import( /* webpackChunkName: "about" */'../views/404.vue');
  }
}];
var router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: routes
});
export default router;