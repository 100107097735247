import router from './router';
var whiteList = ['/404']; // no redirect whitelist

router.beforeEach(function (to, from, next) {
  if (whiteList.indexOf(to.path) !== -1) {
    // 在免登录白名单，直接进入
    next();
  } else {
    if (to.query !== undefined) {
      var token = to.query['token'];
      if (token != null && token === '29e2de96-5dd7-443c-9662-2345b61e3f61') {
        localStorage.setItem('para_token', token);
        localStorage.setItem('username', to.query['username']);
        localStorage.setItem('email', to.query['email']);
        localStorage.setItem('phone', to.query['phone']);
        localStorage.setItem('userId', to.query['userId']);
        next();
      } else {
        var storeToken = localStorage.getItem('para_token');
        if (storeToken === '29e2de96-5dd7-443c-9662-2345b61e3f61') {
          next();
        } else {
          next({
            path: '/404'
          });
        }
      }
    } else {
      var _storeToken = localStorage.getItem('para_token');
      if (_storeToken === '29e2de96-5dd7-443c-9662-2345b61e3f61') {
        next();
      } else {
        next({
          path: '/404'
        });
      }
    }
  }
});